import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Id extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path fill={color} d="M591,3690.4c-184.1-45-351.9-184.1-439.9-360.1L100,3228V108v-3120l51.1-102.3c63.4-131,186.2-253.7,317.1-317.1l102.3-51.2H5000h4429.4l102.3,51.2c130.9,63.4,253.7,186.2,317.1,317.1l51.2,102.3V108v3120l-51.2,102.3c-63.4,130.9-186.2,253.7-317.1,317.1l-102.3,51.2l-4388.5,4.1C2626.7,3702.7,625.8,3698.6,591,3690.4z M3925.9,2368.7c356-94.1,626.1-366.2,720.2-732.4c24.5-98.2,28.6-180,18.4-372.4c-36.8-689.5-298.7-1217.3-705.8-1415.8c-163.7-81.8-372.4-81.8-536,0C3105.5,3.6,2862,380.1,2759.7,875.2c-47.1,227.1-61.4,587.2-32.7,732.4C2841.5,2151.8,3398,2505.8,3925.9,2368.7z M8449.4,2346.2c108.5-79.8,141.2-145.3,141.2-274.2c0-128.9-32.7-194.4-141.2-274.2c-55.2-40.9-81.8-43-1113-49.1c-705.8-4.1-1082.3,0-1133.4,16.4c-206.6,59.3-286.4,343.7-145.3,513.5c104.3,122.8,79.8,120.7,1258.2,114.6C8367.6,2389.2,8394.2,2389.2,8449.4,2346.2z M8449.4,382.1c108.5-79.8,141.2-145.3,141.2-274.2c0-128.9-32.7-194.4-141.2-274.1c-55.2-40.9-81.8-43-1113-49.1c-705.8-4.1-1082.3,0-1133.4,16.4c-206.6,59.3-286.4,343.7-145.3,513.5c104.3,122.8,79.8,120.7,1258.2,114.6C8367.6,425.1,8394.2,425.1,8449.4,382.1z M2880.4-579.5c509.4-386.7,1110.9-386.7,1626.5,2.1l128.9,98.2l351.9-116.6c413.3-139.1,626.1-233.2,699.7-313c128.9-139.1,259.8-521.7,286.4-840.9c16.4-192.3-14.3-278.2-126.8-364.2l-75.7-59.3H3688.6c-2070.5,0-2082.8,0-2138,43c-122.8,90-141.2,139.1-137.1,341.6c4.1,319.2,135,722.2,280.3,879.8c30.7,32.7,120.7,90,202.5,126.8c135,61.4,797.9,296.7,840.9,296.7C2745.4-485.4,2810.9-528.3,2880.4-579.5z"/></g></g>
         </svg>
        )
    }
}

Id.propTypes = {
    color: string,
    size: number
};


Id.defaultProps = {
    color: '#fff',
    size:20
}