import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {salesList} from '../../actions/itemActions';
import ItemListCard from '../../layout/ItemListCard';
import {ItemListContainer} from '../../elements/Container';
import Loader from '../../elements/Loader';

const Catalogo = () => {

    const catalogoList = useSelector( state => state.saleItems);
    const {saleItems, loading, error} = catalogoList;
    const dispatch = useDispatch();

    let {categoria} = useParams();
    
    useEffect( () => {
       dispatch(salesList(categoria)); 
    },[dispatch, categoria]);

    return loading ? <Loader/> :
    error ? <div>{error}</div> :
    
    <ItemListContainer type={'venta'}>    
    <h3>Venta de {categoria}s</h3>
    {saleItems && saleItems.map( (item,idx) =>              
        <ItemListCard                     
            key={idx}
            id={item.id}
            nombre={item.nombre}
            tamaño={item.tamaño}
            precio={new Intl.NumberFormat().format(item.precioVenta)}
            paquete={item.paquete}
            fotoCover={item.fotoCover}
            idx={item.indice}
            origin={'venta'}
        />
    )}            
  </ItemListContainer>  
};

export default Catalogo;
