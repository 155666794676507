import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Functioning extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path fill={color} d="M151.2,4465.3c-48.7-48.7-51.2-84.5-51.2-1147.8c0-950.5,5.1-1104.2,41-1152.9l38.4-56.4l1516.7-2.6h1516.7l307.4-397.1c169.1-217.8,312.6-394.5,320.2-392c7.7,0,151.2,179.3,320.2,397.1l307.4,394.5h133.2c222.9,0,212.6-56.4,212.6,1209.2c0,1063.2-2.6,1099.1-51.2,1147.8c-51.2,51.2-84.5,51.2-2305.8,51.2C235.8,4516.6,202.5,4516.6,151.2,4465.3z M2664.5,4152.8c143.5-46.1,230.6-123,284.4-245.9c79.4-179.3,51.2-261.3-171.7-489.3c-133.2-138.3-189.6-217.8-197.3-271.6c-7.7-74.3-12.8-76.9-135.8-76.9h-128.1l5.1,84.5c5.1,158.8,35.9,212.6,215.2,389.4c164,158.8,181.9,184.5,166.5,248.5c-25.6,107.6-105,161.4-238.3,161.4c-92.2,0-128.1-15.4-189.6-74.3c-41-41-74.3-94.8-74.3-120.4c0-35.9-17.9-41-130.7-28.2c-74.3,10.3-138.3,20.5-143.5,28.2c-7.7,7.7,0,51.2,15.4,99.9c35.9,105,161.4,230.6,284.4,284.4C2334,4188.6,2544.1,4193.8,2664.5,4152.8z M2610.7,2838.5v-140.9H2457h-153.7v123c0,69.2,7.7,133.2,17.9,140.9c7.7,10.3,76.9,17.9,153.7,17.9h135.8V2838.5z"/><path fill={color} d="M6689.4,4283.4C6312.8,4219.4,5972,4040,5682.5,3748c-156.3-156.3-220.4-245.9-315.1-438.1C5211.1,2989.6,5165,2797.5,5165,2467c-2.6-853.1,581.6-1591,1406.5-1783.1c248.5-56.4,643.1-51.2,889,12.8C7983.2,832.5,8423.8,1204,8656.9,1701c125.5,269,164,463.7,164,791.6c-2.6,261.3-12.8,322.8-87.1,543.1c-184.5,550.8-599.5,981.2-1119.6,1160.6C7304.2,4303.9,6994.2,4334.7,6689.4,4283.4z"/><path fill={color} d="M4852.4,420c-263.9-17.9-343.3-51.2-484.2-210.1c-117.9-128.1-205-310-263.9-545.7l-41-174.2l222.9-1834.4c123-1012,230.6-1862.6,238.3-1893.3l12.8-58.9h2456.9h2456.9l12.8,56.4c5.1,33.3,112.7,871.1,233.1,1865.1c205,1665.3,220.3,1819,192.1,1980.4c-56.3,335.6-248.5,648.2-474,768.6l-105,56.4l-2113.6,2.6C6033.5,435.4,4980.5,427.7,4852.4,420z"/></g></g>
         </svg>
        )
    }
}

Functioning.propTypes = {
    color: string,
    size: number
};


Functioning.defaultProps = {
    color: '#fff',
    size:20
}