import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {listPackage} from '../../actions/itemActions';
import PaqListCard from '../../layout/PaqListCard';
import {PaqListContainer} from '../../elements/Container';
import Loader from '../../elements/Loader';

const PaqList = () => {
    
    const packageList = useSelector( state => state.packages );
    const { packages, loading } = packageList;
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(listPackage());
    },[dispatch]);

    return (
        <>
        {loading && <Loader/>}
          <PaqListContainer>
            {packages && packages.map( (packge,idx) =>
                <PaqListCard
                    key={idx}
                    id={packge.id}
                    nombre={packge.nombre}
                    tamaño={packge.tamaño}
                    precio={packge.precioPaquete}
                    paquete={packge.paquete}
                    fotoCover={packge.fotoCover}
                />
            )}
          </PaqListContainer>
        </>
    );
};

export default PaqList;