import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class MesaBillar extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg version="1.1" id="Capa_1" x="0px" y="0px" width={size} height={size} viewBox="0 0 64 64" xmlSpace="preserve">

                <g>
                    <circle fill={color} cx="45" cy="47" r="2"/><circle fill={color} cx="45" cy="35" r="2"/><circle fill={color} cx="39" cy="38" 
                    r="2"/><circle fill={color} cx="45" cy="41" r="2"/><path fill={color} d="m19 36c0 .271.102.516.293.707.384.383 1.03.383 
                    1.414 0l31.74-30.599c.351-.338.553-.813.553-1.301 0-.996-.811-1.807-1.807-1.807-.488 0-.962.201-1.301.553l-30.586 
                    31.727c-.204.204-.306.449-.306.72z"/><circle fill={color} cx="33" cy="41" r="2"/><circle fill={color} cx="39" cy="44" r="2"/>
                    <path fill={color} d="m3 59h58v2h-58z"/><path fill={color} d="m15 41c0 1.654 1.346 3 3 3s3-1.346 3-3c0-.753-.306-1.462-.826-2.017-.059.003-.115.017-.174.017-.801 
                    0-1.555-.313-2.121-.879-.029-.029-.049-.063-.077-.093-1.577.115-2.802 1.397-2.802 2.972z"/><path fill={color} d="m7 29v3.899c1.956-.399 
                    3.5-1.943 3.899-3.899z"/><path fill={color} d="m35.732 25-2.075 2h25.343v28h-54v-28h19.51l1.928-2h-23.438v32h58v-32z"/><path fill={color} 
                    d="m7 53h3.899c-.399-1.956-1.943-3.5-3.899-3.899z"/><path fill={color} d="m57 29h-3.899c.399 1.956 1.943 3.5 3.899 3.899z"/>
                    <path fill={color} d="m57 53v-3.899c-1.956.399-3.5 1.943-3.899 3.899z"/>
                    <path fill={color} d="m51.08 29h-19.497l-9.475 9.134c-.007.007-.016.012-.023.019.587.83.915 1.816.915 2.847 0 2.757-2.243 5-5 5s-5-2.243-5-5c0-2.418 
                    1.726-4.439 4.01-4.9-.001-.034-.01-.066-.01-.1 0-.801.312-1.555.879-2.121l4.703-4.879h-9.662c-.441 3.059-2.861 5.479-5.92 5.92v12.16c3.059.441 
                    5.479 2.861 5.92 5.92h38.16c.441-3.059 2.861-5.479 5.92-5.92v-12.16c-3.059-.441-5.479-2.861-5.92-5.92zm-2.08 12c0 1.201-.542 2.266-1.382 3 
                    .84.734 1.382 1.799 1.382 3 0 2.206-1.794 4-4 4-2.062 0-3.744-1.573-3.957-3.579-.6.359-1.294.579-2.043.579-2.062 0-3.744-1.573-3.957-3.579-.6.359-1.294.579-2.043.579-2.206 
                    0-4-1.794-4-4s1.794-4 4-4c.749 0 1.443.22 2.043.579.213-2.006 1.895-3.579 3.957-3.579.749 0 1.443.22 2.043.579.213-2.006 1.895-3.579 3.957-3.579 
                    2.206 0 4 1.794 4 4 0 1.201-.542 2.266-1.382 3 .84.734 1.382 1.799 1.382 3z"/>
                </g>
            </svg>
        )
    }
}

MesaBillar.propTypes = {
    color: string,
    size: number
};


MesaBillar.defaultProps = {
    color: '#fff',
    size:25
};


