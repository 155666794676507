import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class LittleTike extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg width={size} height={size} version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path fill={color} d="M2699.7,4998.9
            c-22.5-10.2-533.6-507-1134.6-1104L471.4,2811.5h2299.9l2299.9-2L3977.5,3903.2c-958.8,958.8-1104,1095.8-1165.3,1104
            C2773.3,5013.3,2722.2,5009.2,2699.7,4998.9z"/><path fill={color} d="M379.4-1144.4v-3504l53.2-63.4c65.4-77.7,163.5-100.2,251.5-55.2
            c130.8,69.5,124.7-24.5,124.7,1913.5v1758.2l435.4-4.1l433.4-6.1l6.1-1774.5c4.1-1968.7-4.1-1848.1,139-1895.1
            c90-30.7,161.5-12.3,224.9,55.2c49.1,53.1,49.1,53.1,49.1,529.5v478.4h664.4h664.4v-472.3c0-284.2,8.2-486.6,20.4-511.1
            c59.3-110.4,237.1-122.7,335.3-24.5l53.2,53.1l4.1,1780.7l6.1,1780.6l435.4,6.1l433.4,4.1v-1758.2c0-1938.1-6.1-1844,124.7-1913.5
            c87.9-45,186-22.5,251.5,55.2l53.1,63.4v1776.5v1776.6l413-4.1l410.9-6.1l1148.9-1797c633.8-989.5,1171.4-1815.4,1196-1835.8
            c73.6-59.3,196.3-49.1,267.8,22.5c94.1,94,83.8,181.9-49,392.5C7855.6-3250.1,6269.2-784.6,6222.2-737.6l-57.2,61.3l-511.1,6.1l-511.1,6.1
            v214.7v212.6h599h597l725.8-1110.1C9180.4-4581,9256.1-4693.4,9317.4-4726.1c130.8-67.5,302.6,36.8,302.6,184
            c0,79.7,85.9-55.2-1692.8,2659.7C7074.7-580.2,6584.1,149.7,6551.3,166c-36.8,18.4-229,26.6-727.8,26.6h-680.8v1083.5v1083.5H2761.1H379.4
            V-1144.4z M3229.2,2044.8c353.7-118.6,601-337.3,770.7-676.7c363.9-731.9-53.2-1631.4-854.6-1837.9c-157.4-40.9-466.1-40.9-637.8-2
            c-167.6,40.9-413,167.6-549.9,288.3c-314.8,276-490.6,736-435.5,1136.7c45,321,149.2,533.6,380.3,764.6c184,186,406.8,312.8,644,365.9
            C2742.7,2128.6,3026.8,2112.3,3229.2,2044.8z M3415.3-1320.2v-214.7h-654.2h-654.2l-6.1,192.1c-2,106.3,0,206.5,4.1,220.8
            c10.2,22.5,132.9,26.6,660.3,22.5l650.1-6.1V-1320.2z M3425.5-2189.1v-214.7h-664.4h-664.4v214.7v214.7h664.4h664.4V-2189.1z M3415.3-3057.9
            v-214.7h-654.2h-654.2l-6.1,192.2c-2,106.3,0,206.5,4.1,220.8c10.2,22.5,132.9,26.6,660.3,22.5l650.1-6.1V-3057.9z"/></g></g>
            </svg>
        )
    }
}

LittleTike.propTypes = {
    color: string,
    size: number
};


LittleTike.defaultProps = {
    color: '#fff',
    size:25
}