import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Search extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
            <g><path fill={color} d="M927,326.2c-29.4-110-99.8-202-198.4-258.9c-98.9-57.1-214.4-72-325-42.4c-227.8,61.5-363.9,296.5-303.3,524c17.6,66,49.9,125.4,94.8,175.1L67,896.2c-8.3,14.4-10.6,31.3-6.3,47.4c4.3,16.1,14.7,29.7,29.2,37.9c9.5,5.5,20.3,8.4,31.3,8.4c22.3,0,43.1-12,52.2-28.2l158.5-212.9c8.4-14.5,10.6-31.4,6.3-47.5c-4.3-16.2-14.7-29.7-29.2-38.1c-24.3-13.9-56.2-6.8-74.2,10c-34.4-40.6-60-87.9-74-140.5c-51.8-194.3,64.5-395,259.1-447.5c94.5-25.3,192.9-12.7,277.4,36.1c84.1,48.5,144.2,127,169.2,220.8c51.7,194.3-64.5,395-259.2,447.5c-66.3,17.9-135.9,16.9-201.1-3.1c-16.6-5-34,4.3-39.1,20.8c-5,16.5,4.3,34,20.8,39.1c40.4,12.3,82.1,18.5,123.9,18.5c37.4,0,75-4.9,111.7-14.8C851.4,788.7,987.6,553.6,927,326.2z"/></g>
          </svg>
        )
    }
}

Search.propTypes = {
    color: string,
    size: number
};


Search.defaultProps = {
    color: '#B2B2B2',
    size:15
};