import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Change extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><g transform="translate(0.000000,488.000000) scale(0.100000,-0.100000)"><path fill={color} d="M7442.8,4105v-919.8l-281.1-11.5c-669.3-30.6-1189.4-133.9-1694.2-338.5c-363.3-149.1-749.6-382.4-1015.4-615.7l-91.8-82.2l124.3-185.5c130-193.1,300.2-493.3,424.5-741.9c40.2-78.4,74.6-147.2,78.4-151.1c3.8-3.8,86,68.8,183.6,162.5c445.5,430.2,965.7,640.6,1765,715.2c116.6,9.6,267.7,19.1,340.4,19.1h128.1V116.2v-1837.6l-252.4,11.5c-797.4,40.2-1325.1,198.9-1776.4,539.2c-185.5,137.7-313.6,271.5-480,495.3C4725.6-447.9,4622.3-260.5,4266.7,447c-361.4,722.8-504.8,975.2-715.2,1258.2c-558.4,751.5-1378.7,1220-2438.1,1395.9c-254.3,42.1-669.3,78.4-883.4,78.4H100v-608.1v-610l269.6-13.4c589-26.8,1019.2-122.4,1395.9-307.9c246.7-120.5,390.1-225.6,602.3-437.9c263.9-265.8,426.4-518.2,801.2-1252.5c363.3-707.5,491.4-938.9,699.9-1244.8c676.9-996.3,1636.8-1508.7,3044.2-1623.5c133.9-11.5,309.8-21,388.2-21h141.5v-921.7v-923.6l1227.6,1227.6L9900-2327.6L8676.2-1103.8L7452.4,120l1223.8,1223.8L9900,2567.6L8670.5,3797.2L7442.8,5024.8V4105z"/><path fill={color} d="M2408-943.2c-378.6-363.3-799.3-573.7-1359.6-686.5c-242.9-47.8-608.1-86.1-822.2-86.1H100v-615.7v-613.8l284.9,13.4c919.8,40.2,1625.4,235.2,2241.1,615.7c166.4,103.3,483.8,340.4,529.7,393.9c19.1,24.9,3.8,59.3-101.3,218c-122.4,183.6-336.5,558.4-441.7,778.3c-30.6,59.3-59.3,109-65,109C2541.9-818.9,2478.8-874.3,2408-943.2z"/></g></g>
         </svg>
        )
    }
}

Change.propTypes = {
    color: string,
    size: number
};


Change.defaultProps = {
    color: '#fff',
    size:20
}