import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Joy extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
            <path fill={color} d="M4710.6,4977.5c-226.6-49.1-467.2-184.6-644.8-362.1c-698.5-698.5-418.2-1864.3,525.6-2196l121.5-42.1l7-231.3l7-231.3l-100.5-49.1C4325.2,1716.3,1028.8-208.8,935.4-290.5c-74.7-63.1-140.2-154.2-198.6-275.7L648-743.7l-7-635.4c-7-595.7-4.7-642.5,42-780.3c56.1-163.5,142.5-301.4,261.7-411.2c74.8-72.4,3513.6-2067.5,3728.5-2165.6c154.2-70.1,507-72.4,661.1-2.4c58.4,28,904.1,509.3,1880.6,1072.3c1140.1,656.5,1808.2,1055.9,1873.6,1119c56.1,53.7,137.8,172.9,186.9,268.7l86.4,175.2v679.8v679.8l-88.8,177.5c-58.4,121.5-123.8,210.3-198.6,275.7c-93.4,81.8-3389.8,2006.8-3691.1,2156.3l-100.5,49.1l7,233.6l7,231.3l95.8,28c327.1,95.8,642.4,366.8,801.3,689.2C6684.7,4096.8,5801.6,5215.8,4710.6,4977.5z M4806.4,4706.5c189.2-53.7,362.1-289.7,362.1-499.9c0-278-247.6-525.6-525.6-525.6c-140.2,0-257,51.4-364.4,161.2c-212.6,210.3-217.3,504.6-14,719.6C4411.6,4715.9,4593.8,4765,4806.4,4706.5z M4729.3,225.8c7-355.1,9.3-376.1,63.1-432.2c121.5-128.5,303.7-128.5,425.2,0c53.7,56.1,56.1,77.1,63.1,432.2c9.4,427.5-14,399.5,261.7,322.4c369.1-100.4,691.5-336.4,831.7-605.1c60.8-114.5,67.8-151.9,67.8-350.4c0-198.6-7-235.9-67.8-350.4c-100.5-189.2-303.7-376.1-532.6-488.3c-296.7-147.2-441.5-177.6-836.3-177.6c-394.8,0-539.7,30.4-836.3,177.6c-229,112.1-432.2,299-532.7,488.3c-60.7,114.5-67.7,151.8-67.7,350.4c0,198.6,7,236,67.7,350.4c137.8,264,443.9,490.6,808.3,600.4C4743.3,632.3,4720,658,4729.3,225.8z M2498.3-155c207.9-95.8,261.6-292,123.8-448.5c-98.1-112.2-240.6-161.2-425.2-149.5c-345.7,21-549,282.7-378.5,485.9C1970.3-87.3,2255.3-40.5,2498.3-155z"/></g></g>
         </svg>
        )
    }
}

Joy.propTypes = {
    color: string,
    size: number
};


Joy.defaultProps = {
    color: '#fff',
    size:25
};
