import React from 'react';
import styled from 'styled-components';
import {setFlex} from '../utilities';

const Load = ({className}) => {
 
  return (
    <div className={className}>
        <img src="https://kmtonline.com.au/resources/front/landing/images/loader.gif" alt="loader"/>      
    </div>
  );
};

const Loader = styled(Load)`  
  max-width: 100%;
  margin-top: -5rem;
  ${setFlex()};

  img{
    transform: scale(.5);
  }
`;

export default Loader;