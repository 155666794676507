import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Micro extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <g>
              <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)">
                <path fill={color} d="M6219,1129.7c-354.3-92.4-947.3-392.7-1232.1-631.5l-215.6-177.1l2148.5-2140.8l2148.6-2140.8l207.9,269.5c115.5,154,300.4,477.5,415.9,723.9c177.1,377.4,207.9,539.1,207.9,1170.5c0,662.3-23.1,777.8-246.4,1232.1C9145.3,482.8,8205.8,1121.9,7104.6,1175.9C6781.2,1191.2,6380.7,1168.2,6219,1129.7z"/>
                <path fill={color} d="M4340-271.9c-46.2-53.9-161.7-323.4-254.1-600.7c-138.6-408.1-161.7-623.8-138.6-1139.7l30.8-639.2l1055-1055l1047.3-1047.3l677.7-30.8c546.7-23.1,762.3,0,1139.7,130.9c246.4,92.4,500.6,207.9,554.5,261.8c77,77-292.7,492.9-1963.7,2156.3C4871.4-618.5,4401.6-194.9,4340-271.9z"/>
                <path fill={color} d="M2137.6-4776.9L628.2-6455.6l-7.7-577.6c0-554.6-7.7-577.6-277.2-854.8c-223.3-231-269.5-331.1-231-485.1c77-323.4,331.2-331.2,654.6-15.4c277.2,269.5,300.3,277.3,893.3,277.3l616.1,7.7l1617.2,1463.2c893.3,808.6,1624.8,1509.3,1640.3,1563.3c7.7,46.3-408.1,508.3-931.8,1031.9l-947.2,939.6L2137.6-4776.9z"/>
              </g>
            </g>
         </svg>
        )
    }
}

Micro.propTypes = {
    color: string,
    size: number
};


Micro.defaultProps = {
    color: '#fff',
    size:25
};
