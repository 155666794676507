// GET ALL ITEMS
export const ITEM_LIST_REQUEST =       'ITEM_LIST_REQUEST';
export const ITEM_LIST_SUCESS =        'ITEM_LISTS_SUCESS';
export const ITEM_LIST_FAIL =          'ITEM_LIST_FAIL';

// GET ITEM BY ID
export const ITEM_DETAIL_REQUEST =     'ITEM_DETAIL_REQUEST';
export const ITEM_DETAIL_SUCCESS =     'ITEM_DETAIL_SUCCESS';
export const ITEM_DETAIL_FAIL =        'ITEM_DETAIL_FAIL';

// GET PACKAGES
export const PACKAGE_LIST_REQUEST =    'PACKAGE_LIST_REQUEST';
export const PACKAGE_LIST_SUCCESS =    'PACKAGE_LIST_SUCCESS';
export const PACKAGE_LIST_FAIL =       'PACKAGE_LIST_FAIL';

// GET PACKAGE BY ID
export const PACKAGE_DETAIL_REQUEST =  'PACKAGE_DETAIL_REQUEST';
export const PACKAGE_DETAIL_SUCCESS =  'PACKAGE_DETAIL_SUCCESS';
export const PACKAGE_DETAIL_FAIL =     'PACKAGE_DETAIL_FAIL';

// GET SALES LIST
export const SALES_LIST_REQUEST =  'SALES_LIST_REQUEST';
export const SALES_LIST_SUCCESS =  'SALES_LIST_SUCCESS';
export const SALES_LIST_FAIL =     'SALES_LIST_FAIL';

// GET SALES ITEM BY ID
export const SALES_DETAIL_REQUEST =  'SALES_DETAIL_REQUEST';
export const SALES_DETAIL_SUCCESS =  'SALES_DETAIL_SUCCESS';
export const SALES_DETAIL_FAIL =     'SALES_DETAIL_FAIL';