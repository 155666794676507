import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class AirHockey extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg version="1.1" id="Capa_1" x="0px" y="0px" width={size} height={size} viewBox="0 0 512.00002 512.00002" xmlSpace="preserve">

                <g>
                    <path fill={color} d="m320 356.570312c-96 0-146.285156 23.773438-146.285156 36.574219 0 12.796875 50.285156 36.570313 
                    146.285156 36.570313s146.285156-23.773438 146.285156-36.570313c0-12.800781-50.238281-36.574219-146.285156-36.574219zm-36.277344 
                    36.847657c-12.363281.289062-24.695312 1.265625-36.949218 2.925781.152343 1.078125.105468 2.175781-.136719 3.234375-.9375 4.167969-4.640625 
                    7.128906-8.914063 7.132813-.679687.003906-1.355468-.070313-2.019531-.21875-4.875-1.097657-16.273437-3.65625-16.273437-13.347657 0-6.722656 
                    0-15.9375 63.707031-17.996093h.292969c5.046874-.078126 9.207031 3.953124 9.285156 9.003906.078125 5.046875-3.953125 9.203125-9.003906 
                    9.28125zm73.140625 18h-.292969c-5.046874.078125-9.207031-3.949219-9.285156-9s3.953125-9.207031 9.003906-9.285157c12.359376-.289062 
                    24.691407-1.261718 36.945313-2.914062-.40625-2.972656.667969-5.953125 2.878906-7.976562 2.207031-2.027344 5.269531-2.84375 8.191407-2.183594 
                    4.875 1.097656 16.277343 3.65625 16.277343 13.347656-.011719 6.75-.011719 15.957031-63.71875 18.023438zm0 0"/><path fill={color} d="m320 320c-71.3125 
                    0-128.082031 14.628906-160.914062 34.121094-.257813.164062-.523438.3125-.796876.449218-19.480468 11.800782-30.289062 25.359376-30.289062 
                    38.574219 0 35.316407 77.148438 73.140625 192 73.140625s192-37.824218 192-73.140625c0-35.320312-77.148438-73.144531-192-73.144531zm0 
                    128c-81.78125 0-164.570312-18.84375-164.570312-54.855469 0-36.015625 82.789062-54.859375 164.570312-54.859375s164.570312 18.84375 164.570312 
                    54.859375c0 36.011719-82.789062 54.855469-164.570312 54.855469zm0 0"/><path fill={color} d="m246.855469 209.089844v-154.234375c0-30.296875-24.558594-54.855469-54.855469-54.855469s-54.855469 
                    24.558594-54.855469 54.855469v154.234375c3.773438 3.757812 22.992188 10.339844 54.855469 10.339844s51.082031-6.582032 54.855469-10.339844zm0 0"/><path fill={color} d="m128 
                    431.023438v7.832031c0 35.320312 77.148438 73.144531 192 73.144531s192-37.824219 192-73.144531v-7.832031c-32.539062 31.84375-105.34375 
                    53.546874-192 53.546874s-159.460938-21.703124-192-53.546874zm0 0"/><path fill={color} d="m0 182.855469c0 35.320312 77.148438 73.144531 192 
                    73.144531s192-37.824219 192-73.144531c0-28.339844-49.371094-55.933594-118.855469-67.328125v94.757812c0 26.660156-65.664062 
                    27.429688-73.144531 27.429688s-73.144531-.769532-73.144531-27.429688v-94.757812c-69.484375 11.394531-118.855469 38.988281-118.855469 
                    67.328125zm290.359375 25.984375c37.871094-9.46875 47.925781-21.136719 47.925781-25.984375 0-3.007813-3.234375-6.902344-8.886718-10.695313-4.191407-2.816406-5.304688-8.5-2.488282-12.691406 
                    2.820313-4.191406 8.5-5.304688 12.691406-2.484375 9.726563 5.132813 16.136719 14.90625 16.96875 25.871094 0 18.496093-21.367187 
                    33.628906-61.785156 43.714843-.726562.1875-1.472656.28125-2.222656.28125-4.613281-.007812-8.496094-3.453124-9.054688-8.03125-.5625-4.578124 
                    2.375-8.859374 6.851563-9.980468zm-196.71875-51.976563c-37.871094 9.480469-47.925781 21.148438-47.925781 25.992188 0 3.203125 3.65625 6.640625 
                    5.785156 8.402343 3.914062 3.199219 4.492188 8.960938 1.296875 12.871094-3.199219 3.910156-8.960937 4.488282-12.871094 1.292969-7.433593-5.179687-12.050781-13.511719-12.496093-22.566406 
                    0-18.496094 21.367187-33.625 61.785156-43.71875 3.21875-.9375 6.691406-.039063 9.050781 2.339843 2.359375 2.378907 3.226563 5.859376 2.261719 
                    9.070313-.964844 3.207031-3.605469 5.632813-6.886719 6.316406zm0 0"/><path fill={color} d="m320 301.714844c11.8125 0 23.359375.421875 34.585938 1.195312 
                    18.9375-11.609375 29.414062-24.96875 29.414062-37.765625v-44.410156c-32.539062 31.847656-105.34375 53.550781-192 53.550781s-159.460938-21.703125-192-53.550781v44.410156c0 
                    33.296875 67.238281 64.539063 153.460938 71.578125 38.15625-21.429687 98.011718-35.007812 166.539062-35.007812zm0 0"/>
                </g>
            </svg>
        )
    }
}

AirHockey.propTypes = {
    color: string,
    size: number
};


AirHockey.defaultProps = {
    color: '#fff',
    size:25
};


