import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Cancel extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><path fill={color} d="M758.2,104.3c-128,0-231.7,103.7-231.7,231.6s103.7,231.6,231.7,231.6c7.5,0,14.9-0.4,22.2-1.1c-4.6,25.7-8.9,49.4-12.2,67c-1.2,5.8-11.4,16.4-17.3,16.2c-97-3.3-421.4-7.5-421.4-7.5L222.4,332l-6.2-20.9c-1-2.9-2-5.4-2.6-7.1c-7.1-18.6-19.1-30.7-29.1-37.8c-10.2-7.2-18.4-9.8-19.5-10.1l-0.3-0.1l-74.2-18.5c-5-10.2-20.6-17.8-39.3-17.8c-22.7,0-41.1,11-41.1,24.6c0,13.5,18.4,24.5,41.1,24.5c7.4,0,14.3-1.2,20.3-3.3L153,286c1.3,0.5,6.1,2.5,11.4,6.6c6,4.6,12.8,11.6,17.1,22.7c5.7,14.7,35.9,98.5,64.5,177.9c14.3,39.8,28.4,78.7,38.8,107.6c9.1,25.5,15.5,43.3,16.8,47c0.3,1.3,1.1,5.1,1,9.5c0.1,4.7-0.8,9.9-2.8,14c-2.1,4.1-4.9,7.5-11.6,10.4c-20.7,9.1-32.8,29.3-33,50.3c-0.1,13.1,5.4,27.2,16.9,37.1c11.5,9.8,27.9,15.1,48,15.1c5.4,0,13,0,22.3,0l27.3,0c-23.2,7.3-40.1,28.7-40.1,54.3c0,31.6,25.7,57.2,57.2,57.2c31.6,0,57.1-25.5,57.1-57.2c0-25.5-16.8-46.8-39.8-54.2l27.2,0c38.4,0.1,81.8,0.2,123.8,0.3c29.4,0,58.1,0.2,83.8,0.2h25.3c-22.2,8-38.1,28.9-38.1,53.7c0,31.6,25.6,57.2,57.2,57.2s57.2-25.5,57.2-57.2c0-24.7-15.8-45.6-37.9-53.6h25.1c9.7,0,15.2,0,15.2,0c1.9,0,3.1,0,3.1,0h0c8.6,0,15.6-6.9,15.6-15.5c0-8.6-7-15.5-15.5-15.6c0,0-359.7-0.9-422.9-0.9c-15.1,0-23.4-3.8-27.8-7.5c-4.2-3.9-5.9-8.1-6.1-13.5c-0.1-8.5,5.8-18.3,14.1-21.7c13.3-5.6,22.4-15.3,27.3-25.3c2.3-4.8,3.7-9.5,4.6-14.1c2.9,0.7,5.8,1.2,8.9,1.3l407.3,8.3h0.7c0.2,0,0.4,0,0.6,0l1.8,0h0.8c9.1,0,17.4-3.3,24-8.6c6.6-5.2,11.7-12.8,13.2-22.1l14.7-87.7C911.9,539.6,990,447,990,335.9C989.8,208,886.1,104.3,758.2,104.3z M902.7,413.4l-67,67l-77.4-77.5l-77.4,77.5l-67.1-67l77.5-77.5l-77.5-77.5l67.1-67l77.4,77.5l77.4-77.5l67,67l-77.4,77.5L902.7,413.4z"/></g>
         </svg>
        )
    }
}

Cancel.propTypes = {
    color: string,
    size: number
};


Cancel.defaultProps = {
    color: '#fff',
    size:20
}