import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Download extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><path fill={color} d="M893.9,822.8c0,92.2-75,167.2-167.2,167.2H273.3c-92.2,0-167.2-75-167.2-167.2c0-38,30.8-68.8,68.8-68.8s68.8,30.8,68.8,68.8c0,16.3,13.2,29.5,29.5,29.5h453.4c16.3,0,29.5-13.2,29.5-29.5c0-38,30.8-68.8,68.8-68.8C863,754,893.9,784.8,893.9,822.8z M451.3,684.7c12.9,12.9,30.4,20.2,48.7,20.2c18.3,0,35.8-7.3,48.7-20.2l203.2-203.2c26.9-26.9,26.9-70.5,0-97.4c-26.9-26.9-70.5-26.9-97.3,0l-85.7,85.7V78.8c0-38-30.8-68.8-68.8-68.8s-68.8,30.8-68.8,68.8v390.9L345.5,384c-26.9-26.9-70.5-26.9-97.4,0c-26.9,26.9-26.9,70.5,0,97.4L451.3,684.7z"/></g>
         </svg>
        )
    }
}

Download.propTypes = {
    color: string,
    size: number
};


Download.defaultProps = {
    color: '#fff',
    size:20
}