import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Fut extends Component {
    render(){
        const {color, size} = this.props;
        return (
			<svg width={size} height={size} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 450.832 450.832" xmlSpace="preserve">
			<path fill={color} id="XMLID_1131_" d="M417.233,77.771h-28.321V53.922c8.756-3.564,14.944-12.167,14.944-22.188v-7.79
			C403.856,10.741,393.114,0,379.911,0s-23.944,10.741-23.944,23.943v7.79c0,10.021,6.189,18.624,14.945,22.188v23.849h-84.996v-23.85
			c8.756-3.563,14.942-12.166,14.942-22.188v-7.79C300.858,10.741,290.117,0,276.914,0S252.97,10.741,252.97,23.943v7.79
			c0,10.021,6.189,18.625,14.946,22.188v23.849h-84.999V53.922c8.756-3.564,14.944-12.167,14.944-22.188v-7.79
			C197.861,10.741,187.119,0,173.915,0c-13.202,0-23.942,10.741-23.942,23.943v7.79c0,10.021,6.189,18.624,14.944,22.188v23.849H79.92
			V53.922c8.756-3.564,14.944-12.167,14.944-22.188v-7.79C94.864,10.741,84.122,0,70.919,0C57.717,0,46.977,10.741,46.977,23.943v7.79
			c0,10.021,6.188,18.624,14.943,22.188v23.849H33.598c-10.392,0-18.847,8.455-18.847,18.848v257.596
			c0,10.393,8.455,18.848,18.847,18.848H61.92v23.849c-8.756,3.564-14.943,12.167-14.943,22.188v7.79
			c0,13.202,10.741,23.943,23.943,23.943c13.203,0,23.944-10.741,23.944-23.943v-7.79c0-10.021-6.188-18.624-14.944-22.188v-23.85
			h84.997v23.849c-8.755,3.564-14.944,12.167-14.944,22.188v7.79c0,13.202,10.741,23.943,23.944,23.943s23.944-10.741,23.944-23.943
			v-7.79c0-10.021-6.188-18.623-14.944-22.188v-23.85h84.999v23.849c-8.757,3.563-14.946,12.167-14.946,22.188v7.79
			c0,13.202,10.742,23.943,23.946,23.943c13.202,0,23.942-10.741,23.942-23.943v-7.79c0-10.021-6.188-18.623-14.942-22.188v-23.85
			h84.996v23.849c-8.756,3.564-14.945,12.167-14.945,22.188v7.79c0,13.202,10.742,23.943,23.945,23.943s23.944-10.741,23.944-23.943
			v-7.79c0-10.021-6.188-18.624-14.944-22.188v-23.85h28.321c10.393,0,18.848-8.455,18.848-18.848V96.618
			C436.081,86.226,427.626,77.771,417.233,77.771z M373.967,23.943c0-3.222,2.723-5.943,5.945-5.943c3.222,0,5.944,2.722,5.944,5.943
			v7.79c0,3.222-2.723,5.943-5.945,5.943c-3.222,0-5.944-2.722-5.944-5.943V23.943z M270.97,23.943c0-3.222,2.723-5.943,5.946-5.943
			c3.221,0,5.942,2.722,5.942,5.943v7.79c0,3.222-2.722,5.943-5.944,5.943c-3.222,0-5.944-2.722-5.944-5.943V23.943z M234.417,191.519
			c14.989,3.982,26.066,17.67,26.066,33.896c0,16.227-11.077,29.914-26.066,33.896V191.519z M167.973,23.943
			c0-3.222,2.722-5.943,5.944-5.943c3.222,0,5.944,2.722,5.944,5.943v7.79c0,3.222-2.723,5.943-5.946,5.943
			c-3.221,0-5.942-2.722-5.942-5.943V23.943z M64.977,23.943c0-3.222,2.722-5.943,5.943-5.943c3.223,0,5.944,2.722,5.944,5.943v7.79
			c0,3.277-2.667,5.943-5.945,5.943c-3.221,0-5.942-2.722-5.942-5.943V23.943z M32.751,153.373h82.832v144.085H32.751V153.373z
			M76.864,426.889c0,3.222-2.723,5.943-5.945,5.943c-3.221,0-5.942-2.722-5.942-5.943v-7.79c0-3.222,2.722-5.943,5.942-5.943h0.001
			c3.277,0,5.944,2.666,5.944,5.943V426.889z M179.861,426.889c0,3.222-2.723,5.943-5.946,5.943c-3.221,0-5.942-2.722-5.942-5.943
			v-7.79c0-3.222,2.722-5.943,5.942-5.943h0.002c3.222,0,5.944,2.722,5.944,5.943V426.889z M216.417,259.303
			c-6.519-1.735-12.478-5.333-17.07-10.435c-5.803-6.443-8.998-14.773-8.998-23.454c0-16.226,11.078-29.913,26.068-33.896V259.303z
			M216.417,173.111c-24.991,4.286-44.068,26.108-44.068,52.303c0,13.137,4.838,25.744,13.621,35.499
			c8.02,8.907,18.794,14.784,30.447,16.797v77.352h-42.5H33.598c-0.443,0-0.847-0.404-0.847-0.848v-38.756h86.078
			c8.136,0,14.754-6.619,14.754-14.756V150.129c0-8.137-6.618-14.756-14.754-14.756H32.751V96.618c0-0.443,0.403-0.848,0.847-0.848
			h182.819V173.111z M282.858,426.889c0,3.222-2.722,5.943-5.944,5.943c-3.222,0-5.944-2.722-5.944-5.943v-7.79
			c0-3.222,2.723-5.943,5.944-5.943h0.002c3.221,0,5.942,2.722,5.942,5.943V426.889z M385.856,426.889
			c0,3.222-2.723,5.943-5.945,5.943c-3.222,0-5.944-2.722-5.944-5.943v-7.79c0-3.222,2.723-5.943,5.944-5.943h0.001
			c3.222,0,5.944,2.722,5.944,5.943V426.889z M418.081,297.458h-82.833V153.373h82.833V297.458z M418.081,135.373h-86.079
			c-8.136,0-14.754,6.619-14.754,14.756v150.573c0,8.137,6.618,14.756,14.754,14.756h86.079v38.756c0,0.443-0.404,0.848-0.848,0.848
			h-37.321H234.417v-77.344c24.99-4.286,44.066-26.108,44.066-52.304c0-26.194-19.076-48.017-44.066-52.303V95.771h182.816
			c0.443,0,0.848,0.404,0.848,0.848V135.373z"/>
		    <g></g>
		</svg>
        )
    }
}

Fut.propTypes = {
    color: string,
    size: number
};


Fut.defaultProps = {
    color: '#fff',
    size:25
}