import React from 'react';
import useSlider from '../hooks/useSlider';
import SlideNoryan from '../elements/slider/SlideNoryan';
import SlideInflables from '../elements/slider/SlideInflables';
import SlidePong from '../elements/slider/SlidePong';
import SlideFut from '../elements/slider/SlideFut';
import SlideBillar from '../elements/slider/SlideBillar';
import SlideHockey from '../elements/slider/SlideHockey';
import SlideRock from '../elements/slider/SlideRock';
import SlideMaquinitas from '../elements/slider/SlideMaquinitas';
import SlideJumpings from '../elements/slider/SlideJumpings';
import SlideLittle from '../elements/slider/SlideLittle';
import styled from 'styled-components';
import {below} from '../utilities';

const SliderBase = ({className}) => {
  let sliderComponents = [ <SlideNoryan/> , <SlideInflables/>, <SlideJumpings/>, <SlideBillar/>, <SlideHockey/>, <SlideFut/>, <SlideRock/>, <SlideMaquinitas/>, <SlidePong/>, <SlideLittle/> ]; 

  const { offset, addItem } = useSlider({
    total: sliderComponents.length,
    enabled: true,
    useLoaded: false,
    speed: 5500
  });
  
  if(!sliderComponents){
    console.log(addItem);
  }
  
  return (    
    <div className={className}>
      {sliderComponents.map( (component,idx) => {
          return (
            <div key={idx} className="slide" style={{ transform:`translateX(${offset * -100}%)` }}>
              {component}
            </div>
          )
      })}          
    </div>
  );
}

const Slider = styled(SliderBase)`  
  width: 100%;  
  box-sizing: border-box;
  margin-top: 1rem;
  padding: 0;
  display: flex;
  align-items: center;  
  
  .slide{ 
    min-width: 100%;
    height: 100%;
    position: relative;
    transition: .5s;
    border-radius: .8rem;  
  }

  ${below.md`
    margin-top: 2rem;
  `}
`;


export default Slider;
