import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Jumping extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg version="1.1" id="Capa_1" x="0px" y="0px"
	 width={size} height={size} viewBox="0 0 173.571 173.571" 
	 xmlSpace="preserve">
  <g>
	<path fill={color} d="M147.406,68.004c0,8.993-27.139,16.273-60.611,16.273c-33.478,0-60.626-7.28-60.626-16.273
		c0-8.984,27.148-16.273,60.626-16.273C120.267,51.73,147.406,59.013,147.406,68.004z M173.571,71.916v36.315
		c0,2.582-2.095,4.677-4.676,4.677c-2.582,0-4.677-2.095-4.677-4.677V84.259c-14.486,11.454-43.775,17.774-72.753,18.383v32.991
		c0,2.582-2.089,4.677-4.677,4.677s-4.677-2.095-4.677-4.677v-32.991c-28.856-0.608-58.044-6.88-72.585-18.249v23.839
		c0,2.582-2.095,4.677-4.676,4.677c-2.582,0-4.677-2.095-4.677-4.677V71.907c0-0.348,0.131-0.661,0.195-0.99
		C0.198,69.955,0,68.996,0,67.991c0-22.557,44.707-34.729,86.777-34.729c42.069,0,86.764,12.173,86.764,34.729
		c0,0.819-0.183,1.593-0.292,2.384C173.431,70.866,173.571,71.371,173.571,71.916z M164.207,68.004
		c0-11.978-33.119-25.377-77.418-25.377c-44.302,0-77.424,13.394-77.424,25.377c0,11.986,33.122,25.383,77.424,25.383
		C131.087,93.387,164.207,79.984,164.207,68.004z"/>
  </g>
</svg>
        )
    }
}

Jumping.propTypes = {
    color: string,
    size: number
};


Jumping.defaultProps = {
    color: '#fff',
    size:25
};


