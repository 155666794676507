import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Credit extends Component {
    render(){
        const {color, size} = this.props;
        return (
          <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><g transform="translate(0.000000,509.000000) scale(0.100000,-0.100000)"><path fill={color} d="M3665.9,4955.2c-34.2-18.7-155.7-124.5-270.8-236.6l-208.6-202.4l1407.1-1432c775.2-784.5,1609.5-1637.5,1858.5-1895.9l451.4-463.8l619.5,9.3l619.5,9.3L6109.7,2813.4C4992.1,3952.8,4033.3,4908.5,3980.3,4939.7C3871.4,4998.8,3756.2,5008.2,3665.9,4955.2z"/><path fill={color} d="M1352.9,2726.3C671.1,2050.7,341.2,1708.3,325.6,1646c-49.8-171.2,12.5-286.4,326.9-610.2l298.8-308.2h2160.5c1360.4,0,2157.3,12.5,2151.1,31.1c-9.3,28-2857.8,2935.6-2888.9,2948.1C2364.7,3710,1907,3268,1352.9,2726.3z"/><path fill={color} d="M857.9,276.3c-56-24.9-130.7-90.3-165-146.3c-62.3-99.6-62.3-140.1-56-2412.6l9.3-2313l102.7-99.6l99.6-102.7l4274.2-9.3c4240-6.2,4271.2-6.2,4373.9,56c199.2,121.4,193,49.8,193,2509.2c0,2440.6,6.2,2365.9-180.5,2493.6c-81,52.9-292.6,56-4317.8,62.3C1601.9,319.9,942,313.6,857.9,276.3z M2430-570.5l-9.3-336.2h-591.5h-591.5l-9.3,292.6c-3.1,161.9-3.1,311.3,6.2,336.2c12.5,31.1,146.3,40.5,610.2,40.5h594.6L2430-570.5z M7193-2385.4c62.3-34.2,137-77.8,165-96.5c37.4-28,74.7-18.7,171.2,43.6c65.4,43.6,186.8,96.5,267.7,118.3c572.8,140.1,1114.5-435.8,946.4-1005.5c-152.5-523-768.9-747.1-1217.2-442l-118.3,77.8l-90.3-65.4c-529.2-392.2-1270.1-12.4-1270.1,650.6c3.1,386,233.5,688,607.1,784.5C6794.6-2282.7,7065.4-2316.9,7193-2385.4z"/></g></g>
         </svg>
        )
    }
}

Credit.propTypes = {
    color: string,
    size: number
};


Credit.defaultProps = {
    color: '#fff',
    size:20
}