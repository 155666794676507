import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class PingPong extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg version="1.1" id="Capa_1" x="0px" y="0px" width={size} height={size} viewBox="0 0 1000 1000" xmlSpace="preserve">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
    <path fill={color} d="M6026.4,5059.2c-244.2-23.3-562-85.3-823.6-158.9c-213.2-58.1-610.4-203.5-610.4-220.9
    c0-5.8,46.5-62,102.7-126c193.8-219,364.3-565.9,430.2-879.8c44.6-205.4,44.6-587.2,1.9-792.6
    c-158.9-734.5-686-1306.1-1397.2-1513.5c-567.8-164.7-1251.9-42.6-1707.3,302.3c-87.2,65.9-133.7,91.1-143.4,75.6
    c-7.7-11.6-25.2-71.7-38.8-129.8l-23.3-108.5L4071.1-748.7l2255.7-2255.7l85.3,9.7c269.4,34.9,864.3,292.6,1222.8,529
    c1062,701.5,1833.2,1779,2129.7,2970.8c501.9,2030.9-424.4,3860.3-2230.5,4406.7
    C7096.1,5043.7,6491.5,5103.7,6026.4,5059.2z M2838.6,4894.4c-513.5-110.5-928.2-443.8-1151.1-924.4
    c-104.6-222.9-137.6-356.6-149.2-616.2c-31-596.9,248.1-1137.5,751.9-1467c288.7-188,598.8-267.4,974.8-251.9
    c418.6,17.4,746.1,157,1054.2,447.7c234.5,224.8,393.4,500,469,819.7c34.9,143.4,38.8,211.2,31,432.1c-9.7,290.7-38.8,420.5-139.5,633.7
    c-93,197.7-197.7,344.9-350.8,492.2c-234.5,222.9-469,352.7-767.4,424.4C3371.5,4929.3,3022.7,4935.1,2838.6,4894.4z M3303.7,4202.6
    c40.7-48.4,48.4-73.6,40.7-120.1c-19.4-95-69.8-129.8-211.2-147.3c-333.3-40.7-573.6-279.1-608.5-600.7c-15.5-151.2-63.9-213.2-170.5-213.2
    c-100.8,0-164.7,69.8-164.7,178.3c1.9,501.9,453.5,957.3,953.4,963.1C3241.6,4264.6,3257.1,4258.8,3303.7,4202.6z M1763,902.4
    c0-399.2,83.3-812,242.2-1195.7c149.2-358.5,184.1-496.1,184.1-711.2c0-550.4-335.3-1065.8-926.3-1428.2
    c-519.4-317.8-998-668.6-1079.4-786.8c-98.8-147.3-110.5-339.1-32.9-492.2c48.4-93,996.1-1036.8,1089.1-1085.2
    c46.5-23.3,112.4-34.9,203.5-36.8c153.1,0,238.4,32.9,335.2,129.8c102.7,98.8,492.2,647.2,672.5,945.7c313.9,519.3,525.2,740.3,862.4,910.8
    c412.8,207.4,736.4,219,1174.4,42.6c238.4-96.9,480.6-170.5,678.2-209.3c191.9-36.8,600.8-65.9,682.1-50.4
    c62,13.6,42.6,32.9-2011.5,2087.1L1763,1096.2V902.4z"/>
  </g>
</svg>
        )
    }
}

PingPong.propTypes = {
    color: string,
    size: number
};


PingPong.defaultProps = {
    color: '#fff',
    size:25
};


