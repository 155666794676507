import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {detailPackage} from '../../actions/itemActions';
import {PaqDetailContainer} from '../../elements/Container';
import ItemDetailCard from '../../layout/ItemDetailCard';
import Loader from '../../elements/Loader';

function PaqDetail() {

    const packageDetail = useSelector(state => state.packge);
    const {packge, loading } = packageDetail;
    const dispatch = useDispatch();
    
    let { id } = useParams();
    let history = useHistory();
    
    useEffect( () => {
        dispatch(detailPackage(id))
    }, [dispatch, id]);

    const back = () => history.goBack();
    
    return (
        <PaqDetailContainer>
            {loading && <Loader/>}
            { packge &&  <ItemDetailCard
                nombre={packge.nombre}
                precio={packge.precioPaquete}
                desc={packge.descripcion}
                observaciones={packge.observaciones}
                medidas={packge.medidas}
                fotoCover={packge.fotoCover}
                fotos={packge.fotos}
                stock={packge.stock}
                indice={packge.indice}
                back={back}
                type='renta'
            />
            }
        </PaqDetailContainer>
    )
}

export default PaqDetail;